const MAX_ABOUT_ME_LENGTH = 150;

function mergeFilters(state, key, filters) {
  const currentFilter = state.filters[key];

  const currentFilterArray = Array.isArray(currentFilter)
    ? currentFilter
    : currentFilter
    ? currentFilter.split(",")
    : [];
  const newFiltersArray = Array.isArray(filters[key])
    ? filters[key]
    : filters[key]
    ? filters[key].split(",")
    : [];

  const mergedFilters = Array.from(
    new Set([...currentFilterArray, ...newFiltersArray])
  );
  state.filters[key] = mergedFilters.join(",");
}

export const state = () => ({
  profilesAll: [],
  isProfilesAllShortLoading: false,
  profilesAllShort: [],
  profilesDay: [],
  isFavLoading: false,

  session_id: null,

  viewedProfiles: [],
  // this data for page profiles for advertiser

  profilesAdvertiser: [],
  currentProfileForAdvertiser: {},
  serviceInfoProfile: {},
  settingsProfile: {},
  searchTextAdvertiserProfiles: "",
  statistic: {},

  //

  // this data for dashboard page private messages

  advertiserMessages: [],
  orderFilterMessages: "desc",
  searchTextMessages: "",
  messagePage: 1,
  messagePerPage: 20,
  messageTotal: 0,
  date_from: "",
  date_to: "",

  //

  filtersList: {},
  filters: {
    OrderDirection: "desc",
    per_page: 48,
    page: 1,
  },

  filtersChangedByUser: new Set(),
  // nationality, languages - avalibale in current city
  // age, height, weight, price - ranges based on profiles in current city
  available_filter_value: {},

  defaultFilters: {},
  decodedRouteParams: null,

  pageFilter: null,

  keepFiltersWhileEnterCatalog: false,

  /** Deprecated */
  mainSelectedFiltersPage: [], // TODO: AS delete

  currentProf: {},
  countProfiles: null,
  lastPage: false,
  currentPage: 1,
  lastPageNumber: 1,

  urlLevelParams: [],
});

export const getters = {
  getNationalityById: (state) => (id) => {
    return state.filtersList.nationality[id] || "";
  },

  getProfilesAllShort: (state) => state.profilesAllShort,

  getIsProfilesAllShortLoading: (state) => state.isProfilesAllShortLoading,

  getProfilesAll: (state) => state.profilesAll,

  getProfile: (state) => state.currentProf,

  getProfilesDay: (state) => state.profilesDay,

  getFiltersList: (state) => state.filtersList,

  getFilters: (state) => state.filters,

  getFiltersChangedByUser: (state) => state.filtersChangedByUser,

  get_available_filter_value: (state) =>
    state.available_filter_value ||
    state.filtersList?.available_filter_value ||
    {},

  getDecodedRouteParams: (state) => state.decodedRouteParams,

  getLastPage: (state) => state.lastPage,

  getPage: (state) => state.filters.page,

  getCountProfiles: (state) => state.countProfiles,

  getViewedProfiles: (state) => state.viewedProfiles,

  getURLLevelParams: (state) => state.urlLevelParams,

  getDefaultFilters: (state) => state.defaultFilters,

  getMessageTotal: (state) => state.messageTotal,

  getMessagePage: (state) => state.messagePage,

  getKeepFiltersWhileEnterCatalog: (state) =>
    state.keepFiltersWhileEnterCatalog,

  // this data for page profiles for advertiser

  getProfilesAdvertiser: (state) => state.profilesAdvertiser,

  getCurrentProfileForAdvertiser: (state) => state.currentProfileForAdvertiser,

  getServiceInfoProfile: (state) => state.serviceInfoProfile,

  getSettingsProfile: (state) => state.settingsProfile,

  getAdvertiserMessages: (state) => state.advertiserMessages,

  getOrderFilterMessages: (state) => state.orderFilterMessages,

  getSearchTextMessage: (state) => state.searchTextMessages,

  getSearchTextAdvertiserProfiles: (state) =>
    state.searchTextAdvertiserProfiles,

  getStatisticData: (state) => state.statistic,

  detPageFilter: (state) => state.pageFilter,

  getCurrentPage: (state) => state.currentPage,

  getLastPageNumber: (state) => state.lastPageNumber,

  isFavLoading: (state) => state.isFavLoading,

  /** Deprecated */
  getSelectedFilters: (state) => state.mainSelectedFiltersPage, // TODO: AS delete
};

export const mutations = {
  setOrderFilterMessages(state, value) {
    state.orderFilterMessages = value;
  },

  setDateFilter(state, value) {
    state.date_to = "";
    state.date_from = "";

    if (value.length === 1 && value.length !== 0) {
      state.date_from = value[0];
    } else if (value.length === 2) {
      state.date_from = value[0];
      state.date_to = value[1];
    }
  },

  setFavorite(state, payload) {
    console.log("stare", payload);

    state.profilesAllShort.forEach((profile) => {
      if (profile.id === payload.id) {
        profile.isFavorite = payload.isFavorite;
      }
    });

    state.profilesDay.forEach((profile) => {
      if (profile.id === payload.id) {
        profile.isFavorite = payload.isFavorite;
      }
    });

    if (state.currentProf.id === payload.id) {
      state.currentProf.isFavorite = payload.isFavorite;
    }
  },

  setSearchFilter(state, value) {
    state.searchTextMessages = value;
  },

  setProfile(state, payload) {
    state.currentProf = payload;
  },

  setViewedProfiles(state, profiles) {
    state.viewedProfiles = profiles;
  },

  setProfilesAll: (state, payload) => {
    state.profilesAll = payload;
  },

  setIsProfilesAllShortLoading: (state, payload) => {
    state.isProfilesAllShortLoading = payload;
  },

  setProfilesAllShort: (state, data) => {
    // if object convert to array
    const dataArr = [];
    if (data && typeof data === "object") {
      for (const key in data) {
        const profile = data[key];
        if (
          profile?.myDetails[0] &&
          profile?.myDetails[0]?.about_me?.length > MAX_ABOUT_ME_LENGTH
        ) {
          profile.myDetails[0].about_me =
            profile.myDetails[0].about_me
              .slice(0, MAX_ABOUT_ME_LENGTH)
              .replace(/\\n|\\t/g, " ") + "...";
        }
        if (profile?.description?.length > MAX_ABOUT_ME_LENGTH) {
          profile.description = profile.description.slice(
            0,
            MAX_ABOUT_ME_LENGTH
          );
        }
        dataArr.push(profile);
      }
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        const profile = data[i];
        if (
          profile.myDetails[0] &&
          profile.myDetails[0].about_me.length > MAX_ABOUT_ME_LENGTH
        ) {
          profile.myDetails[0].about_me =
            profile.myDetails[0].about_me
              .slice(0, MAX_ABOUT_ME_LENGTH)
              .replace(/\\n|\\t/g, " ") + "...";
        }
        if (profile.description.length > MAX_ABOUT_ME_LENGTH) {
          profile.description = profile.description.slice(
            0,
            MAX_ABOUT_ME_LENGTH
          );
        }
        dataArr.push(profile);
      }
    } else {
      console.log("Error of getting data profiles lists");
    }

    state.profilesAllShort = dataArr;
  },

  supplementProfilesAllShort: (state, data) => {
    data.forEach((profile) => {
      state.profilesAllShort.push(profile);
    });
  },

  setProfilesDay: (state, profiles) => {
    const resultProfiles = [];
    for (let i = 0; i < profiles.length; i++) {
      const profile = profiles[i];

      if (
        profile.myDetails[0] &&
        profile.myDetails[0].about_me &&
        profile.myDetails[0].about_me.length > MAX_ABOUT_ME_LENGTH
      ) {
        profile.myDetails[0].about_me =
          profile.myDetails[0].about_me
            .slice(0, MAX_ABOUT_ME_LENGTH)
            .replace(/\\n|\\t/g, " ") + "...";
      }

      if (
        profile.description &&
        profile.description.length > MAX_ABOUT_ME_LENGTH
      ) {
        profile.description = profile.description.slice(0, MAX_ABOUT_ME_LENGTH);
      }

      resultProfiles.push(profile);
    }
    state.profilesDay = profiles;
  },

  setFiltersList: (state, filters) => {
    state.filtersList = filters;
  },

  setFilters: (state, filters) => {
    state.filters.page = 1;

    for (let key in filters) {
      if (
        filters[key] === "" ||
        filters[key] === null ||
        filters[key]?.length === 0
      ) {
        delete state.filters[key];
      } else if (Array.isArray(filters[key])) {
        mergeFilters(state, key, filters);
      } else {
        state.filters[key] = filters[key];
      }
    }

    state.filters = { ...state.filters };
  },

  setFiltersForce: (state, filters) => {
    state.filters.page = 1;

    for (let key in filters) {
      if (
        filters[key] === "" ||
        filters[key] === null ||
        filters[key] === undefined ||
        filters[key] === 0 ||
        filters[key] === "0" ||
        filters[key] === false ||
        filters[key]?.length === 0
      ) {
        delete state.filters[key];
      } else if (Array.isArray(filters[key])) {
        state.filters[key] = filters[key].join(",");
      } else {
        state.filters[key] = filters[key];
      }
    }

    state.filters = { ...state.filters };
  },

  set_available_filter_value: (state, value) => {
    state.available_filter_value = value;
  },

  resetFilters: (state, toDefault = false) => {
    if (toDefault) {
      state.filters = { ...state.defaultFilters };
    } else {
      state.filters = {
        OrderDirection: "desc",
        per_page: 48,
        page: 1,
      };
    }
  },

  setFiltersChangedByUser: (state, payload) => {
    state.filtersChangedByUser = payload;
  },

  addFilterChangedByUser: (state, payload) => {
    if (Array.isArray(payload) || payload instanceof Set) {
      payload.forEach((item) => {
        state.filtersChangedByUser.add(item);
      });
    } else {
      state.filtersChangedByUser.add(payload);
    }
  },

  setDecodedRouteParams: (state, params) => {
    state.decodedRouteParams = params;
  },

  setMessageTotal: (state, value) => {
    state.messageTotal = value;
  },

  setMessagePage: (state, value) => {
    state.messagePage = value;
  },

  setIsFavLoading: (state, value) => {
    state.isFavLoading = value;
  },

  /** Deprecated */
  addToSelectedFilters(state, payload) {
    // TODO: AS delete this mutation
    state.mainSelectedFiltersPage = payload;
    state.filters[payload.id] = true;
  },

  changePageFilter(state, newFilter) {
    if (state.pageFilter) delete state.filters[state.pageFilter.id];
    state.pageFilter = newFilter;
    state.filters[newFilter.id] = true;
  },

  clearPageFilter(state) {
    if (!state.pageFilter) return;
    delete state.filters[state.pageFilter.id];
    state.pageFilter = null;
  },

  setCountProfiles: (state, number) => {
    state.countProfiles = number;
  },

  setNextPage: (state, number) => {
    state.filters.page = number;
  },

  setLastPage: (state, value) => {
    state.lastPage = value;
  },

  setCurrentPage: (state, value) => {
    state.currentPage = value;
  },

  setLastPageNumber: (state, value) => {
    state.lastPageNumber = value;
  },

  setUrlLevelParams: (state, value) => {
    state.urlLevelParams = value;
  },

  setKeepFiltersWhileEnterCatalog: (state, value) => {
    state.keepFiltersWhileEnterCatalog = value;
  },

  // this data for page profiles for advertiser

  setProfilesAdvertiser: (state, value) => {
    state.profilesAdvertiser = value;
  },

  setOneProfileAdvertiserField(state, { id, field, value }) {
    const profile = state.profilesAdvertiser.find((p) => p.id === id);
    if (profile) {
      profile[field] = value;
    }
  },

  setCurrentProfileForAdvertiser: (state, profile) => {
    state.currentProfileForAdvertiser = profile;
  },

  setCurrentProfileForAdvertiserAvatar(state, avatarUrl) {
    state.currentProfileForAdvertiser.main = avatarUrl;
    state.currentProfileForAdvertiser.main_status = "wait";
  },

  setCurrentProfileForAdvertiserBg(state, bgUrl) {
    state.currentProfileForAdvertiser.background = bgUrl;
    state.currentProfileForAdvertiser.background_status = "wait";
  },

  deletePhotosCurrentProfileForAdvertiser(state, photosIds) {
    state.currentProfileForAdvertiser.gallery =
      state.currentProfileForAdvertiser.gallery.filter(
        (photo) => !photosIds.includes(photo.id)
      );
  },

  movePhotosCurrentProfileForAdvertiser(state, { photosIds, albumId }) {
    for (let i = 0; i < photosIds.length; i++) {
      const photoId = photosIds[i];
      const index = state.currentProfileForAdvertiser.gallery.findIndex(
        (photo) => photo.id === photoId
      );
      if (index == -1) {
        continue;
      }
      state.currentProfileForAdvertiser.gallery[index].albumId = albumId;
      delete state.currentProfileForAdvertiser.gallery[index].grid_data;
    }
  },

  setCurrentProfileForAdvertiserPhotosGridData(state, photoWithNewGridData) {
    for (let i = 0; i < photoWithNewGridData.length; i++) {
      const photo = photoWithNewGridData[i];
      const index = state.currentProfileForAdvertiser.gallery.findIndex(
        (photoItem) => photoItem.id === photo.id
      );
      if (index == -1) {
        continue;
      }
      state.currentProfileForAdvertiser.gallery[index].grid_data = {
        x: photo.x,
        y: photo.y,
        w: photo.w,
        h: photo.h,
      };
    }
  },

  setServiceInfoProfile: (state, data) => {
    state.serviceInfoProfile = data;
  },

  setSettingsProfile: (state, data) => {
    state.settingsProfile = data;
  },

  setAdvertiserMessages: (state, messages) => {
    state.advertiserMessages = messages;
  },

  setSearchTextAdvertiserProfile(state, value) {
    state.searchTextAdvertiserProfiles = value;
  },

  setStatisticData(state, data) {
    state.statistic = data;
  },

  setSessionId(state, sessionId) {
    state.sessionId = sessionId;
  },

  setCurrentProf(state, payload) {
    state.currentProf = payload;
  },

  setDefaultFilters(state) {
    let result = {};
    let keysForDefaultFilters = [
      "city",
      "area",
      "gender",
      "page",
      "new",
      "awaitingCall",
      "qualityMark",
      "verified",
      "incall",
      "outcall",
      "inTour",
    ];

    for (let i = 0; i < keysForDefaultFilters.length; i++) {
      if (state.filters[keysForDefaultFilters[i]]) {
        result[keysForDefaultFilters[i]] =
          state.filters[keysForDefaultFilters[i]];
      }
    }

    state.defaultFilters = result;
  },

  setAdvertiserDialogNewMessages(state, payload) {
    for (let i = 0; i < state.advertiserMessages.length; i++) {
      if (state.advertiserMessages[i].id == payload.id) {
        state.advertiserMessages[i].new_messages = payload.new_messages;
        return;
      }
    }
  },

  setQmStatusForProfileForAdvertiser(state, payload) {
    state.currentProfileForAdvertiser.qm_status = payload;
  },
};

export const actions = {
  // this actions for avdertiser page private messages
  async retrieveMessagesForAdvertiser({ state, commit }, page) {
    try {
      const response = await this.$axios.get("/api/messages", {
        params: {
          order: state.orderFilterMessages,
          search: state.searchTextMessages,
          date_from: state.date_from,
          date_to: state.date_to,
          per_page: state.messagePerPage,
          page: page || state.messagePage,
        },
      });

      if (page) {
        commit("setAdvertiserMessages", response.data.data);
      } else {
        commit("setAdvertiserMessages", [
          ...state.advertiserMessages,
          ...response.data.data,
        ]);
      }

      commit("setMessageTotal", response.data.total);
      commit("setMessagePage", response.data.current_page + 1);
    } catch (error) {
      commit("setAdvertiserMessages", []);
      console.log(error);
    }
  },

  async readAdvertiserMessages(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "messages",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },

  resetFilters({ commit }, payload) {
    commit("resetFilters", payload);
  },

  // this data for page profiles for advertiser
  async retrieveProfilesAdvertiser({ state, commit }) {
    try {
      const { data } = await this.$axios.get(
        `/api/profiles/get?search=${state.searchTextAdvertiserProfiles}`
      );
      commit("setProfilesAdvertiser", data);
    } catch (error) {
      commit("setProfilesAdvertiser", []);
      console.log(error);
    }
  },

  async retrieveServiceInfoProfile({ commit }, slug) {
    try {
      const { data } = await this.$axios.get(
        `/api/profiles/service_info/get/${slug}`
      );

      commit("setServiceInfoProfile", data);
    } catch (error) {
      console.log(error);
    }
  },

  async retrieveSettingsProfile({ commit }, slug) {
    try {
      const { data } = await this.$axios.get(`/api/profiles/setting/${slug}`);

      commit("setSettingsProfile", data);
    } catch (error) {
      commit("setSettingsProfile", {});
      console.log(error);
    }
  },

  async retireveCurrentProfForAdvertiser({ commit, dispatch }, slug) {
    try {
      const { data } = await this.$axios.get(
        `/api/profiles/public_info/get/${slug}`
      );

      await dispatch("retrieveFilters");

      commit("setCurrentProfileForAdvertiser", data);
    } catch (error) {
      commit("setCurrentProfileForAdvertiser", {});
      console.log(error);
    }
  },

  async retrieveViewedProfiles({ commit }) {
    try {
      const { data } = await this.$axios.get("/api/models/viewed");
      commit("setViewedProfiles", data);
    } catch (error) {
      commit("setViewedProfiles", []);
      console.log(error);
    }
  },

  async retrieveCurrentProf({ commit }, { slug }) {
    try {
      const currentProfile = await this.$axios.$get(`/api/models/get/${slug}`);

      if (currentProfile && currentProfile.redirect) {
        throw { message: "Redirect", to: currentProfile.redirect };
      }

      if (Array.isArray(currentProfile)) {
        throw new Error("Profile not fount");
      }

      commit("setProfile", currentProfile);
    } catch (error) {
      if (error.message == "Profile not fount") {
        throw new Error("Profile not fount");
      }

      if (error.message == "Redirect") {
        throw error;
      }
    }
  },

  async retrieveFilters({ commit, rootGetters }) {
    try {
      const filters = await this.$axios.$get(`/api/get_full_data`, {
        params: {
          city: rootGetters["global/getCurrentCity"].id,
        },
      });
      commit("setFiltersList", filters);
    } catch (error) {
      console.log("This user doesn't exist");
      commit("setFiltersList", {});
    }
  },

  async retrieveCurrentProfStat({ commit }, payload) {
    try {
      const { data } = await this.$axios.post("/api/profiles/stat", {
        profile_id: payload,
      });

      commit("setStatisticData", data);
    } catch (error) {
      console.log(error);
    }
  },

  async retrieveProfilesAllShort({ commit, state, dispatch }, payload) {
    commit("setIsProfilesAllShortLoading", true);
    commit("setNextPage", payload?.page || 1);

    try {
      const response = await this.$axios.$get(`/api/models/short`, {
        params: {
          route: payload?.route,
          ...state.filters,
        },
      });

      dispatch("processProfilesShortResponse", response);
    } catch (error) {
      console.log("Error of getting data profiles lists");
      commit("setProfilesAllShort", []);
      if (payload?.route && error.response.status === 404) {
        throw { statusCode: 404, message: "Page not found" };
      }
    } finally {
      commit("setIsProfilesAllShortLoading", false);
    }
  },

  async supplementProfilesAllShort({ commit, state }, route) {
    commit("setNextPage", state.filters.page + 1);
    try {
      const { data, total, current_page, last_page } = await this.$axios.$get(
        "/api/models/short",
        {
          params: {
            route,
            emirate_session_id: state.sessionId,
            ...state.filters,
          },
        }
      );

      const dataArr = [];

      // if sata is object -> convert to array
      if (data && typeof data === "object") {
        for (const key in data) {
          dataArr.push(data[key]);
        }
      }

      if (current_page < last_page) {
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }
      commit("setCurrentPage", current_page);
      commit("setLastPageNumber", last_page);
      commit("setCountProfiles", total);
      commit("supplementProfilesAllShort", dataArr);
    } catch (error) {
      console.log("Error of getting data profiles lists");
      commit("setProfilesAllShort", []);
    }
  },

  async getProfileByPage({ commit }, { page, route }) {
    try {
      commit("setNextPage", page);
      const { data, total, current_page, last_page } = await this.$axios.$get(
        "/api/models/short",
        {
          params: {
            route,
            emirate_session_id: state.sessionId,
            ...state.filters,
          },
        }
      );

      commit("setProfilesAllShort", data);
      commit("setCountProfiles", total);
      commit("setLastPage", current_page >= last_page);
    } catch (error) {
      console.log("Error of getting data profiles lists");
    }
  },

  async getProfilesOfTheDay({ commit }, route) {
    try {
      const profilesDay = await this.$axios.$get("/api/models/day/short", {
        params: {
          route,
        },
      });

      commit("setProfilesDay", profilesDay);
    } catch (error) {
      console.log("Error of geting data profiles data");

      commit("setProfilesDay", []);
    }
  },

  deactivateComment({ commit }, payload) {
    commit("deactivateComment", payload);
  },

  async addToFafourites({ commit }, id) {
    commit("setIsFavLoading", true);
    let response;
    try {
      response = await this.$axios.post("/api/favorites/add", {
        id,
      });
      if (response.data.status) {
        commit("setFavorite", { id, isFavorite: true });
      }
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsFavLoading", false);
    }
    return response;
  },

  async removeFromFavourites({ commit }, id) {
    commit("setIsFavLoading", true);
    let response;
    try {
      response = await this.$axios.post("/api/favorites/delete", {
        id,
      });
      commit("setFavorite", { id, isFavorite: false });
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsFavLoading", false);
    }
    return response;
  },

  async setDefaultFilters({ commit }) {
    commit("setDefaultFilters");
  },

  /**
   * Report profile
   *
   * @param payload - report data
   * @param payload.profile_id - profile id
   * @param payload.type_id - reason id
   * @param payload.name - user name who report
   * @param payload.email - user email who report
   * @param payload.text - report text
   */
  reportProfile(context, payload) {
    this.$axios.post("/api/reports/add", payload);
  },

  processProfilesShortResponse({ commit }, response) {
    const { data, total, current_page, last_page, emirate_session_id, keys } =
      response;
    if (current_page < last_page) {
      commit("setNextPage", current_page + 1);
      commit("setLastPage", false);
    } else {
      commit("setLastPage", true);
    }
    commit("setCurrentPage", current_page);
    commit("setLastPageNumber", last_page);
    commit("setSessionId", emirate_session_id);
    commit("setCountProfiles", total);
    commit("setProfilesAllShort", data);
    commit("setDecodedRouteParams", keys);
  },
};

export const state = () => ({
  allComments: [],
  profileComments: [],
  filters: {
    district: null,
    gender: null,
  },
  codeCity: {}, // TODO: Remove?
  countComments: 0,
  currentPage: 0,
  nextPage: 1,
  lastPage: false,

  page: 1,
  per_page: 20,
  total: 0,

  currentMenu: "reviews",

  // this part for page comments in advertiser cabinet

  allCommentsAdvertiser: [],
  date_to: "",
  date_from: "",
  searchTextComment: "",
});

export const getters = {
  getAllComments: (state) => state.allComments,

  getCurrentPage: (state) => state.currentPage,

  getCountComments: (state) => state.countComments,

  getNextPage: (state) => state.nextPage,

  getLastPage: (state) => state.lastPage,

  getProfileReviews: (state) => state.profileComments,

  getCodeCity: (state) => state.codeCity,

  getCurrentMenu: (state) => state.currentMenu,

  // this part for page comments in advertiser cabinet

  getCommentsForAdvertiser: (state) => state.allCommentsAdvertiser,

  getSearchTextComment: (state) => state.searchTextComment,

  getTotal: (state) => state.total,

  // end part
};

export const mutations = {
  setFirstComments: (state, comments) => {
    state.allComments = comments;
  },

  setFilters: (state, filtersData) => {
    for (let key in filtersData) {
      state.filters[key] = filtersData[key];
    }
  },

  setFilter: (state, payload) => {
    state.filters[payload.key] = payload.value;
  },

  setLoadComments: (state, comments) => {
    for (let comment of comments) {
      state.allComments.push(comment);
    }
  },

  setCurrentPage: (state, numberPage) => {
    state.currentPage = numberPage;
  },

  setNextPage: (state, numberPage) => {
    state.nextPage = numberPage;
  },

  setCountComments: (state, numberComments) => {
    state.countComments = numberComments;
  },

  setLastPage: (state, payload) => {
    state.lastPage = payload;
  },

  setCityCodes: (state, payload) => {
    state.codeCity = payload;
  },

  setProfileComments: (state, comments) => {
    state.profileComments = comments;
  },

  setCurrentMenu(state, value) {
    state.currentMenu = value;
  },

  // this part for page comments in advertiser cabinet

  setCommentsAdvertiser(state, comments) {
    state.allCommentsAdvertiser = comments;
  },

  setDateFilter(state, dates) {
    if (!dates.length) {
      state.date_from = "";
      state.date_to = "";
    } else if (dates.length === 1) {
      state.date_from = dates[0];
      state.date_to = "";
    } else {
      state.date_from = dates[0];
      state.date_to = dates[1];
    }
  },

  setSearchFilter(state, value) {
    state.searchTextComment = value;
  },

  setAdvertiserComments(state, payload) {
    for (let i = 0; i < state.allCommentsAdvertiser.length; i++) {
      if (state.allCommentsAdvertiser[i].id === payload.id) {
        state.allCommentsAdvertiser[i].new_messages = payload.new_messages;
        return;
      }
    }
  },

  setPage(state, page) {
    state.page = page;
  },

  setTotal(state, total) {
    state.total = total;
  },

  // end part
};

export const actions = {
  // this part for page comments in advertiser cabinet

  async retrieveCommentsAdvertiser({ state, commit }, page) {
    try {
      const { data } = await this.$axios.get("/api/comments", {
        params: {
          date_from: state.date_from,
          date_to: state.date_to,
          search: state.searchTextComment,
          per_page: state.per_page,
          page: page || state.page,
        },
      });

      console.log(data);

      if (page) {
        commit("setCommentsAdvertiser", data.data);
      } else {
        commit("setCommentsAdvertiser", [
          ...state.allCommentsAdvertiser,
          ...data.data,
        ]);
      }

      commit("setPage", data.current_page + 1);
      commit("setTotal", data.total);
    } catch (error) {
      commit("setCommentsAdvertiser", []);
      console.log(error);
    }
  },

  async readAdvertiserComments(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "comment",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },

  // end part

  async retrieveCommentaries({ commit, state }, payload = 1) {
    try {
      const commentsPage = await this.$axios.$get("/api/allcomments", {
        params: {
          page: payload,
          per_page: state.per_page,
          ...state.filters,
        },
      });
      commit("setFirstComments", commentsPage.data);
      commit("setCurrentPage", commentsPage.current_page);
      commit("setCountComments", commentsPage.total);
      commit("setNextPage", commentsPage.current_page + 1);

      if (commentsPage.current_page === commentsPage.last_page)
        commit("setLastPage", true);
      else {
        commit("setLastPage", false);
      }
    } catch (error) {
      console.log("An error occurred while loading comments");
    }
  },

  async loadCommentaries({ commit, state }, payload) {
    try {
      const commentsPage = await this.$axios.$get("/api/allcomments", {
        params: {
          page: payload,
          per_page: state.per_page,
          ...state.filters,
        },
      });

      commit("setLoadComments", commentsPage.data);
      commit("setCurrentPage", commentsPage.current_page);
      commit("setCountComments", commentsPage.total);
      commit("setNextPage", commentsPage.current_page + 1);

      if (commentsPage.current_page === commentsPage.last_page)
        commit("setLastPage", true);
    } catch (error) {
      console.log("An error occurred while loading comments");
    }
  },

  async loadCityCodes({ state, commit }) {
    try {
      const { data } = await this.$axios.get("/api/get_cities");

      commit("setCityCodes", data);
    } catch (error) {
      console.log(error);

      state.codeCity = {
        1: "Dubai",
        2: "Abu Dhabi",
      };
    }
  },

  async getProfileReviews({ state, commit }, id) {
    try {
      if (state.currentMenu === "reviews") {
        const { data } = await this.$axios.get(`/api/models/reviews?id=${id}`);

        commit("setProfileComments", data);
      } else {
        const { data } = await this.$axios.get(
          `/api/comments/model?profile_id=${id}`
        );

        commit("setProfileComments", data);
      }
    } catch (error) {
      commit("setProfileComments", []);
      console.log(error);
    }
  },
};

import { render, staticRenderFns } from "./splash.vue?vue&type=template&id=a1b1fad2"
import script from "./splash.vue?vue&type=script&lang=js"
export * from "./splash.vue?vue&type=script&lang=js"
import style0 from "./splash.vue?vue&type=style&index=0&id=a1b1fad2&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IsAdult: require('/app/components/isAdult.vue').default,CNotifications: require('/app/components/global/c-notifications.vue').default,VApp: require('/app/node_modules/vuetify/lib/components/VApp/VApp.js').default,ModalChangeCity: require('/app/components/global/modals/modal-change-city.vue').default,Footer: require('/app/components/Footer.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
installComponents(component, {VApp})

/**
 * Converts a camelCase string to a dashed string
 *
 * @export
 * @param {*} str {string}
 * @return {*} {string}
 * @example
 * camelCaseToDashes('camelCase') // camel-case
 */
export function camelCaseToDashes(str) {
  if (!str) return "";
  return str
    .replace(/([A-Z])/g, "-$1")
    .toLowerCase()
    .replace(/^-/, "");
}

/**
 *Converts a string with spaces to a dashed string
 *
 * @export
 * @param {*} str {string}
 * @return {*} {string}
 * @example
 * spacesToDashes('string with spaces') // string-with-spaces
 */
export function spacesToDashes(str) {
  if (!str) return "";
  return str.toLowerCase().replace(/ /g, "-");
}

/**
 * Clear string from emojis and other special characters
 *
 * @export
 * @param {string} str
 * @return {string} String without emojis
 *
 * @example
 * clearString('Hello World! 👋') // 'Hello World '
 */
export function clearString(str) {
  if (!str) return "";
  // leave letters, numbers, spaces, dashes, underscores
  return str.replace(/[^\w\s.-]/gi, "");
}

/**
 * Converts a underscore string to a string with spaces
 * @export
 * @param {*} str {string}
 * @return {*} {string}
 * @example
 * underscoreToSpaces('underscore_string') // underscore string
 **/
export function underscoreToSpaces(str) {
  if (!str) return "";
  return str.replace(/_/g, " ");
}

/**
 * Capitalizes the first letter of a string
 * @export
 * @param {*} str {string}
 * @return {*} {string}
 * @example
 * capitalize('string') // String
 **/
export function capitalize(str) {
  if (typeof str !== "string") return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Converts a dashed string to a camelCase string
 *
 * @export
 * @param {*} str {string}
 * @return {*} {string}
 * @example
 * dashesToCamelCase('dashed-string') // dashedString
 */
export function dashesToCamelCase(str) {
  if (!str) return "";
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

/**
 * Converts an object to an array of objects
 *
 * @export
 * @param {*} obj {object}
 * @param {string} [keyName='title']
 * @param {string} [valueName='value']
 * @return {*}
 * @example
 * objectToArrayOfObjects({a: 1, b: 2}) // [{title: 'a', value: 1}, {title: 'b', value: 2}]
 */
export function objectToArrayOfObjects(
  obj,
  keyName = "title",
  valueName = "value"
) {
  if (!obj) return [];
  return Object.keys(obj).map((key) => {
    return {
      [keyName]: obj[key],
      [valueName]: key,
    };
  });
}

/**
 *Set selected property to true for options that are included in selected array
 *
 * @export
 * @param {*} options {array} | {string}
 * @param {*} selected {array}
 * @return {*} {array}
 * @example
 * setSelectedOptions([{value: 1, selected: false}, {value: 2, selected: false}], [1]) // [{value: 1, selected: true}, {value: 2, selected: false}]
 */
export function setSelectedOptions(options, selected) {
  if (!options) return [];
  if (!selected || !selected.includes) return options;
  return options.map((option) => {
    option.selected = selected.includes(option.value);
    return option;
  });
}

/**
 *Divides an array into chunks count
 *
 * @export
 * @param {*} array {array}
 * @param {*} chunkCount {number}
 * @return {*} {array}
 *
 * @example
 * chunkArray([1, 2, 3, 4, 5, 6, 7, 8, 9], 4)
 */
export function chunkArray(array, chunkCount) {
  if (!array) return [];
  const result = [];
  const chunkSize = Math.ceil(array.length / chunkCount);
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

/**
 *Extends routes helper
 *
 * @export
 * @param {*} routes routes array
 * @param {*} resolve resolve function
 * @param {*} component component path
 * @param {*} path url path
 * @param {*} name name of route
 */
export function pushRoute(routes, resolve, component, path, name) {
  routes.push({
    name: name,
    path: path,
    component: resolve(component),
    chunkName: component,
  });
}

/**
 *Returns a cropped image url
 *
 * @export
 * @param {*} fullImageUrl string
 * @param {*} size string
 * @param {*} discreetMode boolean
 * @return {*} string
 *
 * @example
 * getCroppedImageUrl('https://www.example.com/image.jpg', '100x100', false) // https://www.example.com/100x100/image.jpg
 */
export function getCroppedImageUrl(fullImageUrl, size, discreetMode) {
  if (discreetMode) return "";
  if (!fullImageUrl) return "";
  if (!size) return fullImageUrl;
  return fullImageUrl.replace(/(\/[^/]+)$/, `/${size}$1`);
}

/**
 *Debounce function to be called after delay
 * @export
 * @param {*} func function to be debounced after delay
 * @param {*} delay number in milliseconds
 * @return {*} function
 * @example
 * debounce(() => console.log('debounced'), 1000)
 */
export function debounce(func, delay) {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

// call func at start and the call it after delay
export function throttle(func, delay) {
  let timeoutId;
  return function () {
    const context = this;
    const args = arguments;
    if (!timeoutId) {
      func.apply(context, args);
      timeoutId = setTimeout(() => {
        timeoutId = null;
      }, delay);
    }
  };
}

/**
 *Converts a route to crumbs
 *
 * @export
 * @param {*} fullPath string - route path
 * @param {*} prepend array - crumbs to prepend
 * @param {*} ignore array - params to ignore
 *
 * @return {*} array
 * @example
 * routeToCrumbs('/a/b/c') // [{href: '/a', text: 'a', pos: 1, disabled: false}, {href: '/a/b', text: 'b', pos: 2, disabled: false}, {href: '/a/b/c', text: 'c', pos: 3, disabled: false}]
 */
export function routeToCrumbs(
  fullPath,
  ignore = [],
  prepend = [
    {
      href: "/",
      text: "Home",
      pos: 0,
      disabled: false,
    },
  ]
) {
  if (!fullPath) return [];
  let crumbs = prepend ? prepend : [];

  const fullPathSplitted = fullPath.split("?")[0];
  const params = fullPathSplitted.startsWith("/")
    ? fullPathSplitted.substring(1).split("/")
    : fullPathSplitted.split("/");
  let path = "";

  params.forEach((param, index) => {
    path += `/${param}`;
    if (!ignore.includes(param)) {
      crumbs.push({
        href: path,
        text: param.replace(/-|_/g, " "),
        pos: index + 1,
        disabled: false,
      });
    }
  });

  return crumbs;
}

/**
 * Prepares route for server. Removes leading slash.
 *
 * @export
 * @param {string} route
 * @return {string}
 *
 * @example
 * catalogRoutePrepareForServer('/a/b/c') // 'a/b/c'
 */
export function catalogRoutePrepareForServer(route) {
  route = route.replace("/", "");
  return route;
}

/**
 * Generates a random uid
 *
 * @export
 * @param {number} [uidLength=10]
 * @return {string}
 *
 * @example
 * generateUid() // 'aBcDeFgHiJ'
 */
export function generateUid(uidLength = 10) {
  let uid = "";
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < uidLength; i++) {
    uid += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return uid;
}

/**
 * Scrolls to element with offset by selector
 *
 * @export
 * @param {string} selector
 * @param {number} offset
 *
 * @example
 * scrollIntoViewWithOffset('#element', 20) // scrolls to element with 20px offset
 */
export function scrollIntoViewWithOffset(selector, offset = 0) {
  const element = document.querySelector(selector);
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition =
      elementPosition - document.body.getBoundingClientRect().top - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
}

/**
 * Returns a messenger link
 *
 * @export
 * @param {string} messenger - WhatsApp, Viber, Signal, WeChat
 * @param {string} phoneNumber
 *
 * @return {string}
 *
 * @example
 * getMessengerLink('WhatsApp', '+123456789') // 'https://wa.me/123456789'
 */
export function getMessengerLink(messenger, phoneNumber) {
  if (!messenger || !phoneNumber) return "";
  let formattedNumber = phoneNumber.replaceAll(" ", "");
  switch (messenger) {
    case "WhatsApp":
      return `https://wa.me/${formattedNumber.replace(
        "+",
        ""
      )}?text=Emiratescort.com%20visitor%20message%3A%20`;
    case "Viber":
      return `viber://chat?number=${formattedNumber.replace("+", "")}`;
    case "Signal":
      // temppory disabled
      return "";
    // return `sgnl://send?phone=${formattedNumber}`;
    case "WeChat":
      // temppory disabled
      // return `weixin://dl/chat?${formattedNumber.replace("+", "")}`;
      return "";
    default:
      return "";
  }
}

/**
 * Return an link to external social media
 *
 * @export
 * @param {object} link - {title: string, link: string}
 *
 * @return {string}
 **/
export function externalProfileLink(link) {
  if (link?.link?.startsWith("http")) return link.link;
  switch (link.title) {
    case "Telegram":
      return `https://t.me/${link.link}`;
    case "Twitter":
      return `https://twitter.com/${link.link}`;
    case "Instagram":
      return `https://instagram.com/${link.link}`;
    case "OnlyFans":
      return `https://onlyfans.com/${link.link}`;
    case "Patreon":
      return `https://patreon.com/${link.link}`;
    case "Fansly":
      return `https://fansly.com/${link.link}`;
    case "Boosty":
      return `https://boosty.to/${link.link}`;
    default:
      return link.link;
  }
}

export function getLinkDomain(url) {
  if (!url) return "Link";
  try {
    return new URL(url).hostname.replace("www.", "");
  } catch (e) {
    return "Link";
  }
}

export const state = () => ({
  allItems: [],
  nextPage: 1,
  perPage: 20,
  lastPage: false,
  numberItems: null,
  searchText: "",
});

export const getters = {
  getAllItems: (state) => state.allItems,

  getNumberItems: (state) => state.numberItems,

  getLastPage: (state) => state.lastPage,

  getSearchText: (state) => state.searchText,
};

export const mutations = {
  setAllItems(state, items) {
    state.allItems = items;
  },

  setUploadItems(state, items) {
    state.allItems = [...state.allItems, ...items];
  },

  setNextPage(state, value) {
    state.nextPage = value;
  },

  setLastPage(state, value) {
    state.lastPage = value;
  },

  setNumberItems(state, number) {
    state.numberItems = number;
  },

  setSearchText(state, text) {
    state.searchText = text;
  },

  setAdvertiserPinBoardNewMessages(state, payload) {
    for (let i = 0; i < state.allItems.length; i++) {
      if (state.allItems[i].id === payload.id) {
        state.allItems[i].new_messages = payload.new_messages;
        return;
      }
    }
  },

  setIsNewById(state, payload) {
    for (let i = 0; i < state.allItems.length; i++) {
      if (state.allItems[i].id === payload.id) {
        state.allItems[i].isNew = payload.isNew;
        return;
      }
    }
  },
};

export const actions = {
  async retrievePinBoards({ state, commit }, user_role) {
    try {
      commit("setNextPage", 1);

      const { data } = await this.$axios.get("/api/pinboard", {
        params: {
          page: state.nextPage,
          search: state.searchText,
          per_page: state.perPage,
          user_role: user_role,
        },
      });

      console.log(data);
      commit("setAllItems", data.data);

      if (data.current_page < data.last_page) {
        commit("setNextPage", state.nextPage + 1);
        commit("setLastPage", false);
      } else {
        commit("setLastPage", true);
      }

      commit("setNumberItems", data.total);
    } catch (error) {
      commit("setAllItems", []);
      console.log(error);
    }
  },

  async uploadItems({ state, commit }, user_role) {
    try {
      const { data } = await this.$axios.get("/api/pinboard", {
        params: {
          page: state.nextPage,
          search: state.searchText,
          per_page: state.perPage,
          user_role: user_role,
        },
      });

      commit("setUploadItems", data.data);

      if (data.current_page < data.last_page) {
        commit("setNextPage", state.nextPage + 1);
      } else {
        commit("setLastPage", true);
      }
      commit("setNumberItems", data.total);
    } catch (error) {
      commit("setUploadItems", []);
      console.log(error);
    }
  },

  async readAdvertiserPinBoard(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "pinboard",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },

  async readAdvertiserPinBoardTopic(context, id) {
    try {
      return await this.$axios.post("/api/notifications/read", {
        type: "pinboard-topic",
        id,
      });
    } catch (error) {
      console.log(error);
    }
  },
};

export const state = () => ({
  // manager: 1,
  // advertiser: 2,
  // member: 3,
  menuItems: {
    1: [
      {
        icon: "IconBillingMenu",
        title: "Balances",
        link: "/dashboard/manager/balances",
      },
      {
        icon: "IconProfMenu",
        title: "Profiles",
        link: "/dashboard/manager/profiles",
        notificationsField: "profiles",
      },
      {
        icon: "IconProfMenu",
        title: "Users",
        link: "/dashboard/manager/users",
      },
      {
        icon: "IconProfMenu",
        title: "Reported Profiles",
        link: "/dashboard/manager/reported-profiles",
        notificationsField: "profiles-reports",
      },
      {
        icon: "IconStatMenu",
        title: "Tariffs",
        link: "/dashboard/manager/tariffs",
        notificationsField: "tariff",
      },
      {
        icon: "IconNewsMenu",
        title: "News",
        link: "/dashboard/manager/news",
      },
      {
        icon: "IconDashMenu",
        title: "Banners",
        link: "/dashboard/manager/banners",
      },
      {
        icon: "IconReviewsMenu",
        title: "Reviews",
        link: "/dashboard/manager/reviews",
        notificationsField: "reviews",
      },
      {
        icon: "IconBlackMenu",
        title: "Black List",
        link: "/dashboard/manager/black-list",
        notificationsField: "blacklist",
      },
      {
        icon: "IconCommMenu",
        title: "Private Messages",
        link: "/dashboard/manager/messages",
        notificationsField: "messages",
      },
      {
        icon: "IconBoardMenu",
        title: "Pin Board",
        link: "/dashboard/pin-board",
        notificationsField: "pinboard",
      },
      {
        icon: "IconBoardMenu",
        title: "Pin Board Users",
        link: "/dashboard/pin-board-users",
        notificationsField: "pinboard_user",
      },
      {
        icon: "IconPhotoCheck",
        title: "Photo Check",
        link: "/dashboard/manager/photo-check",
        notificationsField: "photo",
      },
      {
        icon: "IconPhotoCheck",
        title: "Pornstar Check",
        link: "/dashboard/manager/pornstar-check",
        notificationsField: "wait_pornstar",
      },
      {
        icon: "IconFavorMenu",
        title: "Favourites",
        link: "/dashboard/manager/favourites",
      },
      {
        icon: "IconAccountMenu",
        title: "Settings",
        link: "/dashboard/manager/settings",
      },
      {
        icon: "IconSupportMenu",
        title: "Support",
        link: "/dashboard/manager/support",
        notificationsField: "support",
      },
    ],
    2: [
      {
        icon: "IconDashMenu",
        title: "Dashboard",
        link: "/dashboard",
      },
      {
        icon: "IconProfMenu",
        title: "My profiles",
        link: "/dashboard/my-profiles",
      },
      {
        icon: "IconPrivMessMenu",
        title: "Private Messages",
        link: "/dashboard/private-messages",
        notificationsField: "messages",
      },
      {
        icon: "IconBlackMenu",
        title: "Black List",
        link: "/dashboard/blacklist",
        tariffField: "black_list",
        notificationsField: "blacklist",
      },
      {
        icon: "IconCommMenu",
        title: "Comments",
        link: "/dashboard/comments",
        notificationsField: "comment",
      },
      {
        icon: "IconReviewsMenu",
        title: "Reviews",
        link: "/dashboard/reviews",
        notificationsField: "reviews",
      },
      {
        icon: "IconFavorMenu",
        title: "My Favourites",
        link: "/dashboard/my-favourites",
      },
      {
        icon: "IconBoardMenu",
        title: "Pin Board",
        link: "/dashboard/pin-board",
        tariffField: "pin_board",
        notificationsField: "pinboard",
      },
      {
        icon: "IconBillingMenu",
        title: "Billing",
        link: "/dashboard/billing",
      },
      {
        icon: "IconAccountMenu",
        title: "My account",
        link: "/dashboard/settings",
      },
      {
        icon: "IconNewsMenu",
        title: "News",
        link: "/dashboard/news",
        notificationsField: "news",
      },
      {
        icon: "IconSupportMenu",
        title: "Support",
        link: "/dashboard/support",
        notificationsField: "support",
      },
    ],
    3: [
      {
        icon: "IconPrivMessMenu",
        title: "Private messages",
        link: "/dashboard/member/private-messages",
        notificationsField: "messages",
      },
      {
        icon: "IconPrivMessMenu",
        title: "Notifications",
        link: "/dashboard/member/notifications",
        notificationsField: "notifications",
      },
      {
        icon: "IconPrivMessMenu",
        title: "Comments",
        link: "/dashboard/member/comments",
        notificationsField: "comment",
      },
      {
        icon: "IconPrivMessMenu",
        title: "Reviews",
        link: "/dashboard/member/reviews",
        notificationsField: "reviews",
      },
      {
        icon: "IconFavorMenu",
        title: "My favourites",
        link: "/dashboard/member/my-favourites",
      },
      {
        icon: "IconBoardMenu",
        title: "Pin Board",
        link: "/dashboard/pin-board",
        notificationsField: "pinboard",
      },
      {
        icon: "IconAccountMenu",
        title: "Account & settings",
        link: "/dashboard/member/settings",
      },
      {
        icon: "IconSupportMenu",
        title: "Support",
        link: "/dashboard/member/support",
        notificationsField: "support",
      },
    ],
  },
});

export const getters = {
  getMenuItems(state) {
    return state.menuItems;
  },
};


import { mapGetters } from "vuex";
import { bindDiscreetModeWithLocalstorage } from "~/store/index";

export default {
  name: "HeaderV2",
  components: {
    HeaderMobile: () =>
      import("~/components/v2/common/header/mobile/header-mobile.vue"),
    HeaderDesktop: () =>
      import("~/components/v2/common/header/desktop/header-desktop.vue"),
    MessageDialog: () => import("~/components/v2/message/message-dialog.vue"),
  },

  props: {
    initDisplayOptions: {
      type: Object,
      default: () => {},
    },
    onScrollDisplayOptions: {
      type: Object,
      default: () => {},
    },
    scrollOffsetsOptions: {
      type: Object,
      default: () => {},
    },
    headerClass: {
      type: String,
      default: "",
    },
    forceRow4: {
      type: Boolean,
      default: false,
    },
    firstRowClasses: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      type: null,

      isShowModalSendMessage: false,
      isPmToManager: false,
      pmProfile: null,

      reportProfileModal: {
        isOpen: false,
        profile: null,
      },
    };
  },

  fetch() {
    this.defineType();
  },

  computed: {
    ...mapGetters({
      profile: "profiles/getProfile",
    }),

    headerComponent() {
      return this.type === "mobile" ? "HeaderMobile" : "HeaderDesktop";
    },

    showNewMessageModal() {
      const rName = this.$route.name;
      return (
        rName.includes("catalog-") ||
        rName === "index" ||
        rName.includes("favourites") ||
        rName.includes("profile-slug")
      );
    },
  },

  beforeCreate() {
    bindDiscreetModeWithLocalstorage(this.$store);
  },

  mounted() {
    window.addEventListener("resize", (e) => {
      this.defineType(e.target.innerWidth);
      this.onResize();
    });
    this.defineType(window.innerWidth);
    this.onResize();
    this.$root.$on("sendPM", (profile, toManager = false) =>
      this.openPM(profile, toManager)
    );

    if (this.showNewMessageModal) {
      this.$root.$on("open-message-dialog", (profile) => {
        this.pmProfile = profile;
        this.$refs.MessageDialog?.open();
      });
    }

    this.$root.$on("openReportProfileModal", (profile) => {
      this.reportProfileModal.isOpen = true;
      this.reportProfileModal.profile = profile;
    });
    this.$root.$on("closeReportProfileModal", () => {
      this.reportProfileModal.isOpen = false;
      this.reportProfileModal.profile = null;
    });

    if (
      this.$auth?.user?.user_role == 1 &&
      "serviceWorker" in window.navigator
    ) {
      window.navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        const beamsClient = new PusherPushNotifications.Client({
          instanceId: this.$config.clientIdPusherManager,
          serviceWorkerRegistration: serviceWorkerRegistration,
        });
        console.log("beamsClient", beamsClient);

        beamsClient
          .start()
          .then((beamsClient) => beamsClient.getDeviceId())
          .then((deviceId) =>
            console.log(
              "Successfully registered with Beams. Device ID:",
              deviceId
            )
          )
          .then(() => {
            beamsClient.addDeviceInterest("hello");
            beamsClient.addDeviceInterest(`messages-${this.$auth.user.id}`);
          })
          .then(() => beamsClient.getDeviceInterests())
          .then((interests) => console.log("Current interests:", interests))
          .catch(console.error);
      });
    }
  },

  methods: {
    defineType(width) {
      if (this.$device.isMobile) {
        this.type = "mobile";
      } else if (this.$device.isDesktop && width && width < 1024) {
        this.type = "mobile";
      } else {
        this.type = "desktop";
      }
    },

    onResize() {
      this.$store.commit("global/setMobile", window.innerWidth < 992);
    },

    openPM(profile, toManager = false) {
      this.pmProfile = profile;
      this.isShowModalSendMessage = true;
      this.isPmToManager = toManager;
    },

    closeModalSendMessage() {
      this.isShowModalSendMessage = false;
      this.isPmToManager = false;
    },
  },
};

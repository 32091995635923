
import Pusher from "pusher-js";
import { mapGetters } from "vuex";
import breadcrumbs from "~/components/global/breadcrumbs.vue";
import DashSidebar from "~/components/dashboard/dash-sidebar.vue";
import CNotifications from "~/components/global/c-notifications.vue";
import { routeToCrumbs } from "~/common/helpers.js";
import HeaderV2 from "~/components/v2/common/header/header-v2.vue";

export default {
  name: "Dashboard",

  components: { breadcrumbs, DashSidebar, CNotifications, HeaderV2 },
  middleware: ["auth", "isActive"],

  async fetch() {
    if (this.isAdvertiser || this.isManager) {
      await this.$store.dispatch("tariffs/retrieveTariffs");
    }
  },

  computed: {
    ...mapGetters({
      headerHidden: "global/getPaddingForHiddenHeader",
      balance: "billing/getBalance",
    }),

    isMobile() {
      return this.$store.state.global.mobile;
    },

    isRootDashboard() {
      return this.$route.name === "dashboard";
    },

    breadcrumbsItems() {
      let items = [];

      if (this.$auth.loggedIn && this.$auth.user.user_role === 1) {
        items = routeToCrumbs(this.$route.fullPath, ["manager"]);
      }

      if (this.$auth.loggedIn && this.$auth.user.user_role === 3) {
        items = routeToCrumbs(this.$route.fullPath, ["member"]);
      }

      if (this.$route.name === "dashboard-blacklist") {
        items = [
          {
            href: "/",
            text: this.$t("nav.home"),
            pos: 1,
            disabled: false,
          },
          {
            href: "/dashboard",
            text: this.$t("nav.dashboard"),
            pos: 2,
            disabled: false,
          },
          {
            href: "/dashboard/blacklist",
            text: this.$t("nav.black-list"),
            pos: 2,
            disabled: false,
          },
        ];
      }
      return items;
    },

    userName() {
      return this.$auth.$state.user.name || this.$auth.$state.user.email;
    },

    isAdvertiser() {
      return this.$auth.$state.user.user_role == 2;
    },

    isManager() {
      return this.$auth.$state.user.user_role == 1;
    },

    isMember() {
      return this.$auth.$state.user.user_role == 3;
    },

    profileSettings() {
      return this.$route.name.includes("dashboard-my-profiles-profile");
    },

    pageTitle() {
      switch (this.$route.name) {
        case "dashboard-my-profiles":
          return this.$t("nav.my-profiles");
        case "dashboard-private-messages":
          return this.$t("private-messages");
        case "dashboard-blacklist":
          return this.$t("black-list");
        case "dashboard-comments":
          return this.$t("comments");
        case "dashboard-reviews":
          return this.$t("nav.reviews");
        case "dashboard-my-favourites":
          return this.$t("nav.my-favourites");
        case "dashboard-pin-board":
          return this.$t("nav.pin-board");
        case "dashboard-billing":
          return this.$t("nav.billing_plans");
        case "dashboard-settings":
          return this.$t("nav.account_settings");
        case "dashboard-news":
          return this.$t("nav.news");
        case "dashboard-support":
          return this.$t("nav.support");
        default:
          return this.$t("nav.dashboard");
      }
    },
  },

  mounted() {
    if (this.$auth.loggedIn) {
      this.$store.dispatch("billing/retrieveBilling", 1);
      this.$store.commit("billing/setTariffId", this.$auth.user.tariff_id);
      this.$store.dispatch("notifications/retrieveSidebarNotifications");

      const pusher = new Pusher(this.$config.appKeyPusher, { cluster: "eu" });

      const channel = pusher.subscribe(`notify-${this.$auth.user.id}`);
      channel.bind("notification", (notifications) => {
        console.log(notifications);
        this.$store.commit(
          "notifications/setSidebarNotifications",
          notifications
        );
      });
    }

    // this.$root.$on("set-page-padding", () => {
    //   this.setPagePadding();
    // });
  },

  // methods: {
  //   setPagePadding() {
  //     if (!this.$refs.header) return;
  //     const headerHeight =
  //       this.$refs.header.$el.querySelector("header").clientHeight;
  //     const main = document.querySelector("main");
  //     main.style.paddingTop = `${headerHeight + 10}px`;
  //   },
  // },
};
